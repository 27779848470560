import * as React from "react"
import styled from "styled-components"
import { Itemsbig } from "./Itemsbig"
import { Button02 } from "../Button02"
import StarRating from "../OurEvents/StarRating"
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin : 2em 0 ;
  position: relative;
  justify-self: center;

    .list{
        align-self: center;
    max-width: 1200px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
                            .headline{
                                display: flex;
                                font-weight:700;
                                font-size: 30px;
                                margin : 1em 0 ;
                            }
                            .group{
                                                .hh1{
                                                    font-weight:700;
                                                    font-size: 23px;
                                                    margin : 0.5em 0 ;
                                                }
                                                a{
                                                    margin-left: 1em;
                                                    font-size: 18px;

                                                }
                                                ul{
                                                    margin : 0.8em 0em 0.8em 4em ;
                                                    li{
                                                        font-size: 18px;
                                                        margin: 0.5em 0;
                                                    }

                                                }
                            }
    @media (max-width: 600px) {
        width: 100%;
        padding: 0 2em;
        .headline{
            font-size: 22px;
        }
        .group{
            .hh1{
                font-size: 16px;

            }
            a{
                font-size: 14px;

            }
            ul{
                li{font-size: 15px;}
            }
        }
    }
    }
    .firstep{
        max-width: 1440px;
        width: 100%;
        position: relative;
        flex-direction: column;
        display: flex;
        justify-self: center;
        font-size: 32px;
        color: #0044B0;
        font-weight: 700;
        align-self: center;
        margin: 2em 0;
        p,
        .btn {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        @media (max-width: 500px) {
        font-size: 15px;

        }
            }
    .banner{
        position: relative;
        width: 100%;
        
        display:flex;
        justify-content: center;
        color: #FFFFFF;
        margin-bottom: 3em;
        @media (max-width:500px) {
            max-height: auto;
            img{
            object-fit: contain;
            width: 100%;
            }
        }
    }
    .rating{
        .question{
        font-size: 30px;
        font-weight: 700;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 1em;
        }
        .val{
            font-size: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            margin: 0 0 0.3em 0;

        }
        .sharediv{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            /*padding: 0 5.5em 0 0;*/
            margin: 1em 0 3em 0;
            div{
                border: 3px solid #0044B0;
                width: 300px;
            }
    }
@media (max-width: 500px) {
margin:  0;
padding: 0 2em;
.question{
  font-size: 20px;
}
.val{
  font-size: 16px;
}
.sharediv{
  div{
            border: 2px solid #0044B0;
            width: 150px;
        }
}
}

    }

    .socialnet{
        position: relative;
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 0 3em;
  /*.link {
    width: 100%;
  }*/
  .social{
    margin: 1em 0 3em 0;
    width: 300px;
    display: flex;
    justify-content: space-around;
   

  a { 
    width: 10px;

    img {
    width: 40px;

    :hover{

  border: none;
  color: #FFFFFF;
  transform: scale(1.1);
  will-change: transform;

}

  }
  }


  }
  @media (max-width: 780px) {
    width: 100%;
    justify-content: center;
    /*padding-right: 0;
    padding-left: 30px;*/
    margin-bottom: 30px;
    border-right: none;
    left: -10px;
  } ;
    }
`

const Questions= ({data, banner}) => {

    let arregloa=[
        {
            "id": 1,
            "qq": "Qué es ser una ATC",

            "aa": [
                    {
                    "id": 1,
                    "text":"Somos reconocidos por Autodesk como Centro Autorizado de Capacitación y Certificación; por lo tanto, dictamos cursos oficiales y todos nuestros instructores  cuentan con Certificación de Instructor Autodesk.",
                    },
                ],
        },
        {
            "id": 2,
            "qq": "Cómo verifico que son una ATC",

            "aa": [
                    {
                    "id": 1,
                    "text":"Puedes visitar el siguiente link de confirmación:",
                    "details":[
                        {"id": 1,
                        "detail":"En City indicas Lima, Perú.",
                        },
                        {"id": 2,
                        "detail":"En Partner Type indicas Training Center.",
                        },

                    ],
                    "link":"https://www.autodesk.com/partners/locate-a-reseller?partnerType=Training%20Center",  
                    },
                ],
        },
        {
            "id": 3,
            "qq": "A quiénes están dirigidos sus cursos",

            "aa": [
                    {
                    "id": 1,
                    "text":"Nuestros cursos están dirigidos para estudiantes y profesionales de los rubros de Ingeniería y Arquitectura, Proyectistas, Diseñadores en general, etc.",
                    },
                ],
        },
        {
            "id": 4,
            "qq": "Las clases son virtuales",

            "aa": [
                    {
                    "id": 1,
                    "text":"¡Así es! Nuestras clases son clases 100% virtuales en vivo donde podrás interactuar con el instructor y tus compañeros.",
                    },
                ],
        },

        {
            "id": 5,
            "qq": "Qué tipo de certificados entregan",

            "aa": [
                    {
                    "id": 1,
                    "text":"Entregamos Certified of Completion Autodesk según curso:",
                    "details":[
                                {"id": 1,
                                "detail":"Especifica el curso y las horas indicadas.",
                                },
                                {"id": 2,
                                "detail":"Tiene un código único con valor internacional.",
                                },
                                {"id": 3,
                                "detail":"La certificación es digital y podrás acceder a ellos cada vez que lo requieras desde tu cuenta oficial Autodesk.",
                                },
                            ],
                    },

                    {
                    "id": 2,
                    "text":"Certificado de Participación en todos nuestros cursos a nombre de ITCAD CENTER.",
                    },

                    {
                    "id": 3,
                    "text":"Insignias Digitales Credly.",
                    },
                ],
        },  
        {
            "id": 6,
            "qq": "Qué son las insignias digitales",

            "aa": [
                    {
                    "id": 1,
                    "text":"Las insignias digitales proporcionan información portátil y verificable sobre tus habilidades y logros. Una nueva forma de identificar el talento en función de la competencia y la aptitud. Además ayuda a los empleadores a seleccionar mejor el talento humano de acuerdo a su experiencia.",
                    },
                ],
        },
        {
            "id": 7,
            "qq": "Cómo obtengo una insignia digital",

            "aa": [
                    {
                    "id": 1,
                    "text":"Obtendrás una insignia cuando concluyan satisfactoriamente cualquiera de nuestros cursos.",
                    },
                ],
        },
        {
            "id": 8,
            "qq": "Cómo puedo usar las insignias digitales",

            "aa": [
                    {
                    "id": 1,
                    "text":"Podrás compartir tus insignias digitales en línea o en las redes sociales como LinkedIn, Twitter, Facebook, Google Plus, etc.; mejorando así tu presencia profesional. Así como incluir el logo en tus tarjetas personales, firmas de correo electrónico y en tu CV.",
                    },
                ],
        },
        {
            "id": 9,
            "qq": "Ustedes brindan el instalador del software",

            "aa": [
                    {
                    "id": 1,
                    "text":"Lo ideal es que tengas el software instalado para el inicio del curso; en caso no puedas conseguirlo, nosotros te brindaremos una licencia original temporal sólo por el tiempo que dura el curso.",
                    },
                ],
        },
        {
            "id": 10,
            "qq": "En qué idioma debo descargar el programa",

            "aa": [
                    {
                    "id": 1,
                    "text":"El curso se dicta en español, sin embargo, el software empleado para cada curso es en inglés, ya que son más comerciales.",
                    },
                ],
        },
        {
            "id": 11,
            "qq": "Qué versión debo descargar del programa",

            "aa": [
                    {
                    "id": 1,
                    "text":"Todos nuestros cursos se dictan en la versión más reciente del software; es por ello que recomendamos usar siempre la última versión disponible o 2 años anteriores como máximo.",
                    },
                ],
        },
        {
            "id": 12,
            "qq": "En qué nivel se dictan sus cursos",

            "aa": [
                    {
                    "id": 1,
                    "text":"Nuestros cursos son completos, es decir, se inicia desde el nivel básico y se culmina en el nivel intermedio/avanzado.",
                    },
                ],
        },
        {
            "id": 13,
            "qq": "Las clases son grabadas",

            "aa": [
                    {
                    "id": 1,
                    "text":"Nuestras clases también son grabadas y puedes acceder a los videos por un año (contando desde el inicio del curso) a través del aula virtual a manera de visualización.",
                    },
                ],
        },
        {
            "id": 14,
            "qq": "La asistencia es obligatoria",

            "aa": [
                    {
                    "id": 1,
                    "text":"Durante la clase se toma la asistencia respectiva. En caso el estudiante no pueda participar por cruce de horarios del trabajo y/o universidad deberá justificarlo en el grupo de su curso y listo.",
                    },
                    {
                    "id": 2,
                    "text":"La asistencia no influye en el promedio del curso, pero sí se premiará a los estudiantes que asistan al 70% de las clases con 2 puntos adicionales en su proyecto. Lo más importante es presentar sus trabajos en la fecha y hora indicada por el instructor.",
                    },
                ],
        },
        {
            "id": 15,
            "qq": "Cuáles son sus métodos de pago",

            "aa": [
                    {
                    "id": 1,
                    "text":"Ahora puedes realizar el pago de tu matrícula de manera más rápida y segura desde nuestra página web en ''Compra aquí!''.",
                    },
                    {
                    "id": 2,
                    "text":"Otra forma son los pagos vía depósito o transferencia bancaria a una de nuestras cuentas oficiales a nombre de ITCAD PERÚ SAC, solicitar a través de nuestro WhatsApp de Informes.",
                    },
                    {
                    "id": 3,
                    "text":"También aceptamos Yape y Plin (Solicita el número a nuestro chat de Informes).",
                    },
                    {
                    "id": 4,
                    "text":"Asimismo, puedes solicitar el link de pago online si deseas pagar con cualquier tarjeta de crédito o débito.",
                    },
                ],
        },
    ]


   
    return (
<Container>
<div className="list">
    <div className="headline">Preguntas frecuentes de nuestra comunidad</div>

{arregloa?.map(element=>(
    <div key={element?.id} className="group">
       <div className="hh1">{element?.id}. ¿{element?.qq}?</div>

        {element.aa.map((answer) => (
        <div>
                    <Itemsbig key={answer.id}>
                        {answer.text}
                    </Itemsbig>
                    {answer.link&&
                    <a>{answer.link}</a>}

                    {answer.details &&
                    (
                        <ul>
                        {answer.details.map((dt)=>(
                            <li key={dt.id}>{dt.detail}</li>
                        ))}
                        </ul>
                    )
                }
        </div>
                    
                ))}

    </div> 
))

}
</div>

<div className="firstep">
        <p>El primer paso para el éxito, está aquí</p>
            <div className="btn">
                <a href={data?.whatsapp_link} target="_blank">
                <Button02>Matrícula aquí</Button02>
                </a>
            </div>
</div>

<div className="banner">
            <a href={banner?.banner_image_link} target="_blank">
                <img style={{width: '100%'}} src={banner?.banner_image}/>
            </a>
</div>

<div className="rating">
        <div className="question">¿Participaste en el evento?</div>
        <div className="val">Déjanos una valoración</div>
        <StarRating />
        <div className="sharediv"><div></div></div>
</div>

<div className="socialnet">
<div className="social">
            <a
                className="link"
                href={data?.facebook_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/fb.svg" alt="facebook de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={data?.instagram_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/ig.svg" alt="instagram de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={data?.tiktok_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/tiktok.svg" alt="tiktok de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={data?.linkedin_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/linkedin.svg" alt="linkedin de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={data?.youtube_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/youtube.svg" alt="youtube de empresa dedicada a educar a especialistas 3d" />
            </a>
            </div>
</div>
</Container>
)}
export default Questions;