import React from "react"
import styled from "styled-components"
import { IconContext } from "react-icons"
import { IoEllipse } from "react-icons/io5"

const Container = styled.div`
  display: flex;
  /*min-width: 700px;*/
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;

  text-align: justify;

  color: #302f2f;
  margin : 1em 0 1em 1em ;

  .icon {
        margin-right: 10px;
     }
     @media (max-width: 900px) {
      font-size: 14px;
      /*min-width: 400px;*/
     }
     @media (max-width: 500px) {
      font-size: 14px;
      /*min-width: 400px;*/
      padding: 0 ;
     }
`
export const Itemsbig = ({ children }) => {
  return (
    <Container>
      <div className="icon">
      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.681818" y="0.681818" width="7.63636" height="7.63636" stroke="#0044B0" stroke-width="1.36364"/>
</svg>
      </div>
      {children}
    </Container>
  )
}
