import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getCoursesPage,getGeneralInfo } from "../../providers"

import { MainLayout } from "../../components/Layouts/MainLayout"
import { Hero4 } from "../../components/Hero4"

import "../../assets/css/global.css"
import Questions from "../../components/Questions/index"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const FrequentQuestions  = () => {
  const [coursesPages, setCoursesPages] = useState([])
  const [generalInfo, setGeneralInfo] = useState({})
  const [image, setImage] = useState({})


  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const _coursesPage = await getCoursesPage()
        setCoursesPages(_coursesPage[0])
        
        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])
        setImage(_generalInfo[0].background_image)

      } catch (error) {
        console.log(error)
      }
    }
    fetchCourses()
  }, [])





  return (
    <MainLayout>
      <Container>
      <Hero4 backgroundImage="/images/preguntas_frecuentes.png" />
        <Questions data={generalInfo} banner={coursesPages}/>
      </Container>
    </MainLayout>
  )
}

export default FrequentQuestions
